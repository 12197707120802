body {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #112;
  color: white;
  font-family: monospace;
}

@keyframes time_tick {
  from {
    color: #f88;
  }
  to {
    color: inherit;
  }
}


#countdown {
  font-size: 2em;
}

.ticker {
  animation: time_tick ease-out 0.5s;
}

#main {
  max-width: 500px;
  text-align: center;
  font-size: 2em;
}
